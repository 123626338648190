import { nanoid, Sym } from '@edclass/fe-ui'
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from '@material-tailwind/react'
import { useQueryClient } from '@tanstack/react-query'
import WavesurferPlayer from '@wavesurfer/react'
import clsx from 'clsx'
import {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import WaveSurfer from 'wavesurfer.js'

import Avatar from '@/components/Avatar'
import Divider from '@/components/Divider'
import MoreDropdown from '@/components/MoreDropdown'
import MoreDropdownItem from '@/components/MoreDropdown/MoreDropdownItem.tsx'
import Repeater from '@/components/Repeater'
import SubjectOption from '@/components/Select/SubjectOption.tsx'
import Tooltip from '@/components/Tooltip'
import { POPOVER_TAILWIND_COLOR_CLASSNAME } from '@/constants/builder.ts'
import { EDIcon } from '@/constants/constants.ts'
import { QueryKeys } from '@/constants/query.ts'
import { getFileIcon, getThumbType } from '@/helpers/fs.ts'
import { updateQueryData } from '@/helpers/query.ts'
import { promiseToast } from '@/helpers/toast.tsx'
import { getFsName, getFsService, getFsUrl } from '@/services/fs.ts'

function FileTagDialog({
  item,
  setEdit,
  onChange,
  loading,
}: {
  loading: boolean
  item: FsItem
  setEdit: SetState<boolean>
  onChange: (meta: string[]) => void
}) {
  const [meta, setMeta] = useState(
    (item.tags?.custom ?? []).map((t) => {
      return {
        id: nanoid(),
        meta: t,
      }
    }) ?? [],
  )

  return (
    <Dialog open handler={() => setEdit(false)}>
      <DialogHeader>Edit Media Tags</DialogHeader>
      <DialogBody divider className="max-h-[60vh] overflow-y-auto">
        <div>
          <div>Automatic content tags</div>
          <div className="text-blue-gray-500">
            automatic content tag cannot be edited, it will be automatically
            re-tagged when the content that use the media is created or updated.
          </div>
          <div className="py-4">
            <FileTag showLabel fsTag={item.tags} />
          </div>
        </div>
        <Divider className="mb-4" />
        <div>
          <div>Additional Custom Keyword Tag</div>
          <div className="py-4">
            <Repeater
              id={`edit-media-${item.key}`}
              value={meta}
              onChange={setMeta}
              fields={[
                {
                  field: 'meta',
                  props: {
                    label: 'Tag',
                  },
                  type: 'input',
                },
              ]}
            />
          </div>
        </div>
      </DialogBody>
      <DialogFooter className="flex justify-end">
        <Button
          loading={loading}
          onClick={() => {
            onChange(
              meta.map(({ meta }) => {
                return meta
              }),
            )
          }}
        >
          Submit
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

function FileTagLabel({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) {
  return (
    <div className="flex-c-2">
      <div className="uppercase text-body font-bold min-w-[120px]">{title}</div>
      <div className="flex gap-2 flex-wrap">{children}</div>
    </div>
  )
}

function FileTag({ fsTag, showLabel }: { fsTag?: FsTag; showLabel?: boolean }) {
  const tags = useMemo(() => {
    return Object.entries(fsTag || {}).filter(
      ([, v]) => Array.isArray(v) && v.length > 0,
    )
  }, [fsTag])

  return tags.length < 1 ? null : (
    <div
      className={clsx(
        showLabel ? 'flex flex-col gap-4' : 'p-2 flex gap-2 flex-wrap',
      )}
    >
      {tags.map(([key, tag], i) => {
        const k = key as keyof FsTag
        return (
          <Fragment key={i}>
            {tag.map((t, j) => {
              const r = () => {
                switch (k) {
                  case 'subjects':
                  case 'exBoards': {
                    const opt = (
                      <SubjectOption
                        bgOn
                        noIconMargin
                        noIconPadding
                        noIconGap
                        wrapperClassName="py-1 px-1.5 gap-1 rounded text-body"
                        subject={t as Subject}
                      />
                    )
                    return showLabel ? (
                      <FileTagLabel
                        title={k === 'subjects' ? 'subjects' : 'ex-boards'}
                      >
                        {opt}
                      </FileTagLabel>
                    ) : (
                      opt
                    )
                  }
                  case 'skills': {
                    const opt = (
                      <SubjectOption
                        bgOn
                        noIconMargin
                        noIconPadding
                        noIconGap
                        wrapperClassName="py-1 px-1.5 gap-1 rounded text-body"
                        subject={t as Subject}
                      />
                    )
                    return showLabel ? (
                      <FileTagLabel title="Skill Verb">{opt}</FileTagLabel>
                    ) : (
                      opt
                    )
                  }
                  case 'questions': {
                    const item = t as LessonTrackItem
                    const getIcon = () => {
                      switch (item.questionType) {
                        case 'mcq':
                          return (
                            <Sym className="!text-[18px]">{EDIcon.MCQ}</Sym>
                          )
                        case 'essay':
                          return (
                            <Sym className="!text-[18px]">{EDIcon.Essay}</Sym>
                          )
                        case 'dnd':
                          return (
                            <Sym className="!text-[18px]">{EDIcon.DND}</Sym>
                          )
                        case 'puzzle':
                          return (
                            <Sym className="!text-[18px]">{EDIcon.Puzzle}</Sym>
                          )
                        case 'doodle':
                          return (
                            <Sym className="!text-[18px]">{EDIcon.Doodle}</Sym>
                          )
                      }
                    }
                    const opt = (
                      <div
                        title="Question"
                        className={clsx(
                          'py-1 px-1.5 flex-c gap-1 rounded text-body',
                          POPOVER_TAILWIND_COLOR_CLASSNAME.question,
                        )}
                      >
                        {getIcon()}
                        <span>{item.title}</span>
                      </div>
                    )
                    return showLabel ? (
                      <FileTagLabel title="Questions">{opt}</FileTagLabel>
                    ) : (
                      opt
                    )
                  }
                  case 'lessons': {
                    const opt = (
                      <div
                        title="Lesson"
                        className={clsx(
                          'py-1 px-1.5 flex-c gap-1 rounded text-body text-white',
                          'bg-indigo-500',
                        )}
                      >
                        <Sym className="!text-[18px]">{EDIcon.Lesson}</Sym>
                        <span>{(t as DoodleItem).title}</span>
                      </div>
                    )
                    return showLabel ? (
                      <FileTagLabel title="Lessons">{opt}</FileTagLabel>
                    ) : (
                      opt
                    )
                  }
                  case 'keywords': {
                    const opt = (
                      <div
                        title="Lesson Keyword"
                        className={clsx(
                          'py-1 px-1.5 flex-c gap-1 rounded text-body bg-purple-500 text-white',
                        )}
                      >
                        <Sym className="!text-[18px] rotate-[-45deg]">key</Sym>
                        <span>{t?.toString()}</span>
                      </div>
                    )
                    return showLabel ? (
                      <FileTagLabel title="Lesson Keywords">{opt}</FileTagLabel>
                    ) : (
                      opt
                    )
                  }
                  case 'topics': {
                    const opt = (
                      <div
                        title="Topic"
                        className={clsx(
                          'py-1 px-1.5 flex-c gap-1 rounded text-body bg-light-green-600 text-ed-text',
                        )}
                      >
                        <Sym className="!text-[18px]">{EDIcon.Topic}</Sym>
                        <span>{(t as Topic)?.title}</span>
                      </div>
                    )
                    return showLabel ? (
                      <FileTagLabel title="Topics">{opt}</FileTagLabel>
                    ) : (
                      opt
                    )
                  }
                  case 'skillTags': {
                    const opt = (
                      <div
                        title="Skill Tag"
                        className={clsx(
                          'py-1 px-1.5 flex-c gap-1 rounded text-body bg-teal-500 text-white',
                        )}
                      >
                        <Sym className="!text-[18px]">{EDIcon.Objective2}</Sym>
                        <span>{(t as SkillTag)?.title}</span>
                      </div>
                    )
                    return showLabel ? (
                      <FileTagLabel title="Skill Tag">{opt}</FileTagLabel>
                    ) : (
                      opt
                    )
                  }
                  case 'keySkills': {
                    const opt = (
                      <div
                        title="Key Skill"
                        className={clsx(
                          'py-1 px-1.5 flex-c gap-1 rounded text-body bg-light-green-600 text-ed-text',
                        )}
                      >
                        <Sym className="!text-[18px]">{EDIcon.Objective3}</Sym>
                        <span>{(t as KeySkill)?.title}</span>
                      </div>
                    )
                    return showLabel ? (
                      <FileTagLabel title="Key Skill">{opt}</FileTagLabel>
                    ) : (
                      opt
                    )
                  }
                  case 'pathways': {
                    const opt = (
                      <div
                        title="Pathway"
                        className={clsx(
                          'py-1 px-1.5 flex-c gap-1 rounded text-body bg-teal-500 text-white',
                        )}
                      >
                        <Sym className="!text-[18px]">{EDIcon.Pathway}</Sym>
                        <span>{(t as Pathway)?.name}</span>
                      </div>
                    )
                    return showLabel ? (
                      <FileTagLabel title="Pathways">{opt}</FileTagLabel>
                    ) : (
                      opt
                    )
                  }
                  case 'custom': {
                    const opt = (
                      <div
                        title="Custom"
                        className={clsx(
                          'py-1 px-1.5 flex-c gap-1 rounded text-body',
                          'bg-deep-purple-500 text-white',
                        )}
                        key={`${i}-${j}`}
                      >
                        <Sym className="!text-[18px]">shoppingmode</Sym>
                        <span>{(t as Topic)?.title || t?.toString()}</span>
                      </div>
                    )
                    return showLabel ? null : opt
                  }
                  /*default: {
                    const opt = (
                      <div
                        title={k === 'topics' ? 'Topics' : k}
                        className={clsx(
                          'text-body flex-c-2 py-1 px-1.5 bg-blue-500 rounded',
                          k === 'topics' ? 'bg-green-500 text-ed-text' : '',
                        )}
                        key={`${i}-${j}`}
                      >
                        {(t as Topic)?.title || t?.toString()}
                      </div>
                    )
                    return opt
                  }*/
                }
              }
              return <Fragment key={`${i}-${j}`}>{r()}</Fragment>
            })}
          </Fragment>
        )
      })}
    </div>
  )
}

export default function FileCard({
  disabled,
  item,
  size = 'md',
  onClick,
  active,
  params,
  queryKey,
}: {
  disabled?: boolean
  item: FsItem
  size?: 'md' | 'sm'
  onClick?: React.MouseEventHandler
  active?: boolean
  params?: ReqParams
  queryKey: QueryKeys
}) {
  const queryClient = useQueryClient()
  const thumbType = getThumbType(item)
  const mediaRef = useRef<HTMLVideoElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [isPlay, setIsPlay] = useState(false)
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null)
  const [fullscreen, setFullscreen] = useState(false)
  const [waveLoading, setWaveLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)

  useEffect(() => {
    const cb = () => {
      setFullscreen(!fullscreen)
    }
    const { current } = mediaRef
    current?.addEventListener('fullscreenchange', cb)
    return () => {
      current?.removeEventListener('fullscreenchange', cb)
    }
  }, [mediaRef, fullscreen])

  return (
    <div
      data-thumb-type={thumbType}
      role={onClick ? 'button' : 'listitem'}
      className={clsx(
        'group',
        'relative flex flex-col text-white',
        active ? 'bg-blue-500 hover:bg-blue-200' : 'bg-ed-dark-blue',
        'rounded-lg hover:bg-white/90 hover:text-ed-text transition-colors',
      )}
      onClick={onClick}
    >
      <div className="z-[1] absolute top-2 left-2">
        <Avatar user={item.uploader} tip />
      </div>
      <div className="z-[1] absolute top-2 right-2">
        <button
          onClick={() => {
            return thumbType === 'video'
              ? mediaRef.current?.requestFullscreen({
                  navigationUI: 'hide',
                })
              : setFullscreen(!fullscreen)
          }}
          className="text-white p-2 bg-ed-dark-blue rounded-full opacity-50 hover:opacity-100"
        >
          <Sym className="!text-[20px]">fullscreen</Sym>
        </button>
      </div>
      {thumbType === 'image' ? (
        <div
          className={clsx(
            size === 'md' ? 'h-[200px]' : 'h-[140px]',
            'overflow-hidden',
            'cc',
            'flex-shrink-0',
          )}
        >
          <img
            ref={imgRef}
            className={clsx(
              'max-w-full w-auto rounded-lg',
              //size === 'md' ? 'h-[200px]' : 'h-[140px]',
              imgRef.current
                ? imgRef.current.naturalHeight > 200
                  ? 'object-cover'
                  : ''
                : '',
            )}
            src={getFsUrl(item.key)}
          />
        </div>
      ) : null}
      {thumbType === 'video' ? (
        <div className="group relative flex-shrink-0">
          <button
            onClick={() => {
              if (!isPlay) {
                mediaRef.current?.play()
              } else {
                mediaRef.current?.pause()
              }
            }}
            className={clsx(
              'rounded-full bg-black/30 h-12 w-12 absolute top-[50%] left-[50%] translate-x-[-1.5rem] translate-y-[-1.5rem] cc',
              isPlay && 'opacity-0 transition-opacity group-hover:opacity-100',
            )}
          >
            <Sym className="text-white !text-[48px]">
              {isPlay ? 'pause' : 'play_arrow'}
            </Sym>
          </button>
          <video
            controls={fullscreen}
            onPlay={() => {
              setIsPlay(true)
            }}
            onPlaying={() => {
              setIsPlay(true)
            }}
            onPause={() => {
              setIsPlay(false)
            }}
            onEnded={() => {
              setIsPlay(false)
            }}
            ref={mediaRef}
            className={clsx(
              size === 'md' ? 'h-[200px]' : 'h-[140px]',
              ' w-full object-cover rounded-lg',
            )}
            src={getFsUrl(item.key)}
          />
        </div>
      ) : null}
      {thumbType === 'sheet' ? (
        <div
          className={clsx(
            'flex-shrink-0 cc',
            size === 'md' ? 'h-[200px]' : 'h-[140px]',
          )}
        >
          <Sym className="text-green-500 !text-[48px]">table</Sym>
        </div>
      ) : null}
      {thumbType === 'pdf' ? (
        <div
          className={clsx(
            'flex-shrink-0 cc',
            size === 'md' ? 'h-[200px]' : 'h-[140px]',
          )}
        >
          <Sym className="text-green-500 !text-[48px]">picture_as_pdf</Sym>
        </div>
      ) : null}
      {thumbType === 'doc' ? (
        <div
          className={clsx(
            'flex-shrink-0 cc',
            size === 'md' ? 'h-[200px]' : 'h-[140px]',
          )}
        >
          <Sym className="text-light-blue-500 !text-[48px]">description</Sym>
        </div>
      ) : null}
      {thumbType === 'unknown' ? (
        <div
          className={clsx(
            'flex-shrink-0 cc',
            size === 'md' ? 'h-[200px]' : 'h-[140px]',
          )}
        >
          <Sym className="!text-[48px]">unknown_document</Sym>
        </div>
      ) : null}
      {thumbType === 'audio' && (
        <div
          className={clsx(
            size === 'md' ? 'h-[200px]' : 'h-[140px]',
            'group relative flex-shrink-0',
          )}
        >
          <div className="pt-4 cc h-full">
            <div className="p-4 w-full">
              <WavesurferPlayer
                height={size === 'md' ? 100 : 70}
                //waveColor="#009FE3"
                //progressColor="#E15A24"
                waveColor="rgb(200, 0, 200)"
                progressColor="rgb(100, 0, 100)"
                url={getFsUrl(item.key)}
                onReady={(ws) => {
                  setWavesurfer(ws)
                  setIsPlay(false)
                }}
                onPlay={() => setIsPlay(true)}
                onPause={() => setIsPlay(false)}
              />
            </div>
          </div>
          <button
            onClick={() => {
              wavesurfer && wavesurfer.playPause()
            }}
            className={clsx(
              'z-[2]',
              'rounded-full bg-black/30 h-12 w-12 absolute top-[50%] left-[50%] translate-x-[-1.5rem] translate-y-[-1.5rem] cc',
              isPlay && 'opacity-0 transition-opacity group-hover:opacity-100',
            )}
          >
            <Sym className="text-white !text-[48px]">
              {isPlay ? 'pause' : 'play_arrow'}
            </Sym>
          </button>
        </div>
      )}
      <div className="bg-black/30 group-hover:bg-transparent  h-full">
        <div className="flex-c-2 p-4 w-full">
          <div className="flex-c-2 w-[calc(100%-24px)]">
            <Sym className="!text-[18px]">{getFileIcon(thumbType)}</Sym>
            <div className="truncate w-full">
              <Tooltip content={getFsName(item.key)}>
                <div className="text-body truncate text-left">
                  {getFsName(item.key)}
                </div>
              </Tooltip>
            </div>
          </div>
          {!disabled && (
            <MoreDropdown placement="bottom-end">
              <MoreDropdownItem
                onClick={() => {
                  setEdit(true)
                }}
                icon={EDIcon.Edit}
              >
                Edit
              </MoreDropdownItem>
              <MoreDropdownItem className="text-red-500" icon={EDIcon.Delete}>
                Remove
              </MoreDropdownItem>
            </MoreDropdown>
          )}
        </div>
        {!disabled && <FileTag fsTag={item.tags} />}
      </div>
      {fullscreen && ['audio', 'image'].includes(thumbType) && (
        <Dialog
          size="xxl"
          open={fullscreen}
          handler={() => setFullscreen(false)}
        >
          <DialogHeader className="justify-end">
            <button onClick={() => setFullscreen(false)}>
              <Sym>close</Sym>
            </button>
          </DialogHeader>
          <DialogBody className="cc h-full overflow-y-auto">
            {thumbType === 'audio' && (
              <div className="w-full p-12">
                <div className="w-full relative">
                  {waveLoading ? (
                    <Spinner className="z-[100] absolute top-1/2 left-1/2" />
                  ) : null}
                  <WavesurferPlayer
                    onLoad={() => setWaveLoading(true)}
                    onLoading={() => setWaveLoading(true)}
                    mediaControls
                    height={600}
                    //waveColor="#009FE3"
                    //progressColor="#E15A24"
                    waveColor="rgb(200, 0, 200)"
                    progressColor="rgb(100, 0, 100)"
                    url={getFsUrl(
                      item.key,
                      import.meta.env.VITE_APP_BUCKET_NAME,
                    )}
                    onReady={(ws) => {
                      setWavesurfer(ws)
                      setIsPlay(false)
                      setWaveLoading(false)
                    }}
                    onPlay={() => setIsPlay(true)}
                    onPause={() => setIsPlay(false)}
                  />
                </div>
              </div>
            )}
            {thumbType === 'image' && (
              <div className="w-full p-12">
                <div className="w-full relative">
                  <img
                    src={getFsUrl(
                      item.key,
                      import.meta.env.VITE_APP_BUCKET_NAME,
                    )}
                  />
                </div>
              </div>
            )}
            {thumbType === 'unknown' && <Sym>file</Sym>}
          </DialogBody>
        </Dialog>
      )}
      {edit && (
        <FileTagDialog
          loading={updateLoading}
          onChange={(meta) => {
            setUpdateLoading(true)
            promiseToast(
              getFsService()
                .updateCustomTags(item.key, meta)
                .then(() => {
                  updateQueryData(
                    {
                      ...item,
                      tags: {
                        ...item.tags,
                        custom: meta,
                      },
                    },
                    queryClient,
                    [queryKey, params],
                  )
                }),
              {
                loading: 'Updating custom tags',
                success: 'Custom tags updated',
                error: (e) => `${e}`,
              },
            ).finally(() => {
              setUpdateLoading(false)
              setEdit(false)
            })
          }}
          item={item}
          setEdit={setEdit}
        />
      )}
    </div>
  )
}
