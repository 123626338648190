import './main.css'
import 'react-loading-skeleton/dist/skeleton.css'
import '@xyflow/react/dist/style.css'
//import '@material-symbols/font-300/rounded.css'
import 'material-symbols'

import React from 'react'
import ReactDOM from 'react-dom/client'

import Root from '@/Root.tsx'

ReactDOM.createRoot(document.getElementById('app')!).render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
)
