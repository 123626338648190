// eslint-disable-next-line react-refresh/only-export-components
import { lazy } from 'react'

import {
  AdminCrudPath,
  AdminLessonChildrenPath,
} from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const AdminDAQBuilderPage = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminElementBuilderPage/AdminDAQBuilderPage/index.tsx'
  )
})

// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminElementBuilderPage/AdminDAQBuilderPage/DAQIndexPage.tsx'
  )
})

// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminElementBuilderPage/AdminDAQBuilderPage/DAQCreatePage.tsx'
  )
})

// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminElementBuilderPage/AdminDAQBuilderPage/DAQEditPage.tsx'
  )
})

// eslint-disable-next-line react-refresh/only-export-components
const Archive = lazy(() => {
  return import(
    '@/pages/AdminPage/AdminElementBuilderPage/AdminDAQBuilderPage/DAQArchive.tsx'
  )
})

export default {
  path: AdminLessonChildrenPath.DAQ,
  element: <AdminDAQBuilderPage />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:daqId/${AdminCrudPath.EDIT}`,
      element: <Edit />,
    },
    {
      path: `archives`,
      element: <Archive />,
    },
  ],
}
