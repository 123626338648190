import { KEY_STAGES } from '@edclass/fe-common'

import { ED_BG, ED_BLUE } from '@/constants/color.ts'
import { EDIcon } from '@/constants/constants.ts'

export const POPOVER_TAILWIND_COLOR_CLASSNAME: Record<
  LessonQuestionType | 'text' | 'question' | 'daq',
  string
> = {
  question: '!bg-blue-500',
  mcq: '!bg-blue-500',
  essay: '!bg-light-green-800',
  //'elearn': '!bg-green-600',
  game: '!bg-deep-purple-400',
  puzzle: '!bg-teal-600',
  doodle: '!bg-purple-500',
  dnd: '!bg-indigo-500',
  text: '!bg-ed-beige',
  daq: '!bg-blue-gray-500',
}

export const QUESTION_CAT_OPTIONS: {
  label: string
  icon: string
  kind: 'dnd' | 'mcq' | 'essay'
  reverse?: boolean
}[] = [
  {
    label: 'MCQ',
    kind: 'mcq',
    icon: EDIcon.MCQ,
  },
  {
    label: 'Essay',
    kind: 'essay',
    icon: EDIcon.Essay,
  },
  {
    label: 'DND',
    kind: 'dnd',
    icon: EDIcon.DND,
  },
]

export const MCQ_CHOICE_KIND_OPTIONS: {
  label: string
  icon: string
  kind: MCQKind
  reverse?: boolean
}[] = [
  {
    label: 'Word',
    kind: 'word',
    icon: 'list',
  },
  {
    label: 'Picture -> Word',
    kind: 'picture-word',
    icon: 'view_sidebar',
  },
  {
    label: 'Word -> Picture',
    kind: 'word-picture',
    icon: 'view_sidebar',
    reverse: true,
  },
  {
    label: 'Video -> Word',
    kind: 'video-word',
    icon: 'smart_display',
  },
  {
    label: 'Abstract -> Word',
    kind: 'abstract-word',
    icon: 'subject',
  },
]

export const ESSAY_KIND_OPTIONS: {
  label: string
  icon: string
  kind: EssayKind
  reverse?: boolean
}[] = [
  {
    label: 'Free Text',
    kind: 'ft',
    icon: 'keyboard',
  },
  {
    label: 'Fill Blank',
    kind: 'fill-blank',
    icon: 'text_select_end',
  },
  {
    label: 'Fill Drag and Drop',
    kind: 'fill-dnd',
    icon: 'trackpad_input',
    reverse: true,
  },
]

export const MCQ_OPTION_STYLES: Array<{
  value: MCQStyle
  label: string
  icon: string
}> = [
  {
    value: 'radio',
    label: 'Radio',
    icon: 'radio_button_checked',
  },
  {
    value: 'check',
    label: 'Checkbox',
    icon: 'check_box',
  },
  {
    value: 'button',
    label: 'Button',
    icon: 'buttons_alt',
  },
]

export const DND_CHOICE_KIND_OPTIONS: {
  label: string
  icon: string
  kind: DndKind
  reverse?: boolean
}[] = [
  {
    label: 'Text',
    kind: 'text',
    icon: 'text_fields',
  },
  {
    label: 'Spelling',
    kind: 'spelling',
    icon: 'spellcheck',
  },
  {
    label: 'Icons',
    kind: 'icons',
    icon: 'image',
  },
  {
    label: 'Number',
    kind: 'number',
    icon: 'tag',
  },
  {
    label: 'Abstract',
    kind: 'abstract',
    icon: 'description',
  },
]

export const PUZZLE_KIND_OPTIONS: {
  label: string
  icon: string
  kind: PuzzleKind
  reverse?: boolean
}[] = [
  {
    label: 'Word',
    kind: 'word-search',
    icon: 'manage_search',
  },
  {
    label: 'Matching',
    kind: 'matching',
    icon: 'join_inner',
  },
  {
    label: 'Crossword',
    kind: 'crossword',
    icon: 'crossword',
  },
  /*{
    label: 'Swapping',
    kind: 'sorting',
    icon: 'cards',
  },*/
]

export const DOODLE_KIND_OPTIONS: {
  label: string
  icon: string
  kind: DoodleKind
  reverse?: boolean
}[] = [
  {
    label: 'Blank',
    kind: 'blank',
    icon: 'square',
  },
  {
    label: 'Grid',
    kind: 'grid',
    icon: 'grid_on',
  },
  {
    label: 'Lined',
    kind: 'lined',
    icon: 'table_rows',
  },
]

export const DEFAULT_POPUP_WIDTH = 800
export const DEFAULT_POPUP_HEIGHT = 600

export const DEFAULT_MEDIA_TRACK_SETTINGS: LessonMediaTrackSettings = {
  coordinate: { x: 0, y: 0 },
  color: POPOVER_TAILWIND_COLOR_CLASSNAME.mcq,
  layout: {
    containerSize: 'fixed-size',
  },
  style: {
    css: {
      width: DEFAULT_POPUP_WIDTH,
      height: DEFAULT_POPUP_HEIGHT,
      backgroundColor: `${ED_BG}EC`,
      color: 'white',
      borderColor: ED_BLUE,
    },
    overlay: false,
    overlayBdOpacity: 0.6,
    overlayColor: '#000',
    overlayBdBlur: false,
    border: true,
  },
  wizardSteps: {
    init: 0,
  },
}

export const DEFAULT_MEDIA_TRACK: Omit<MediaTrack, 'id'> = {
  tags: [],
  type: 'mcq',
  seconds: 0,
  setting: DEFAULT_MEDIA_TRACK_SETTINGS,
  questionBanks: [],
  /*doodle: undefined,
  questionKind: undefined,
  game: undefined,
  dndItems: [],
  puzzle: undefined,*/
  textItem: undefined,
}

export const DEFAULT_QUESTION_SETTINGS: LessonQuestionSetting = {
  titleAlignment: 'start',
  bodyDecoration: false,
  //layout: 'left',
  mediaLayout: 'right',
  mediaWidth: undefined,
  contentWidth: undefined,
  choicesColumn: 2,
  choiceStyle: 'check',
  layoutTemplate: 'title_t-body_m-media_n-opt',
}

export const DEFAULT_ELEARN_TRACK: Omit<ElearnTrack, 'id'> = {
  tags: [],
  type: 'mcq',
  setting: DEFAULT_MEDIA_TRACK_SETTINGS,
  questionBanks: [],
  textItem: undefined,
}

export const DEFAULT_ELEARN_TRACK_SETTINGS: ElearnTrackSettings = {
  color: POPOVER_TAILWIND_COLOR_CLASSNAME.mcq,
  layout: {
    containerSize: 'fixed-size',
  },
  style: {
    css: {
      width: DEFAULT_POPUP_WIDTH,
      height: DEFAULT_POPUP_HEIGHT,
      backgroundColor: `${ED_BG}EC`,
      color: 'white',
      borderColor: ED_BLUE,
    },
    overlay: false,
    overlayBdOpacity: 0.6,
    overlayColor: '#000',
    overlayBdBlur: false,
    border: true,
  },
  wizardSteps: {
    init: 0,
  },
}

export const DEFAULT_ALL_TYPE = {
  mcq: {
    choiceOptions: [],
  },
  essay: {
    modelAnswers: [],
    autoTrigger: false,
  },
  dnd: {
    dndOptions: [],
    slots: [],
    draggableColumn: 4 as DndColumnCount,
    droppableColumn: 4 as DndColumnCount,
  },
  puzzle: {
    timeLimit: 0,
  },
  game: {
    timeLimit: 0,
  },
  doodle: {},
}

export const DEFAULT_ESSAY_FORM: Omit<EssayItemFormValue, 'id' | 'directory'> =
  {
    marking: 1,
    allowVerbal: false,
    questionType: 'essay',
    kind: 'ft',
    body: '',
    title: '',
    setting: DEFAULT_QUESTION_SETTINGS,
    image: '',
    difficulty: 1,
    yearGroups: KEY_STAGES[0].years,
    ...DEFAULT_ALL_TYPE,
  }

export const DEFAULT_MCQ_FORM: Omit<MCQItemFormValue, 'id' | 'directory'> = {
  marking: 1,
  allowVerbal: false,
  topic: undefined,
  questionType: 'mcq',
  kind: 'word',
  body: '',
  title: '',
  setting: DEFAULT_QUESTION_SETTINGS,
  image: '',
  difficulty: 1,
  yearGroups: KEY_STAGES[0].years,
  ...DEFAULT_ALL_TYPE,
}

export const DEFAULT_DND_ITEM_FORM: Omit<DndItemFormValue, 'id' | 'directory'> =
  {
    allowVerbal: false,
    questionType: 'dnd',
    kind: 'text',
    body: '',
    title: '',
    image: undefined,
    video: undefined,
    audio: undefined,
    setting: DEFAULT_QUESTION_SETTINGS,
    yearGroups: KEY_STAGES[0].years,
    difficulty: 1,
    marking: 1,
    ...DEFAULT_ALL_TYPE,
  }
export const DEFAULT_PUZZLE_FORM: Omit<
  PuzzleItemFormValue,
  'id' | 'directory'
> = {
  questionType: 'puzzle',
  allowVerbal: false,
  title: '',
  body: '',
  image: undefined,
  setting: {
    fields: [],
    matching: [['', '']],
    crossword: {
      across: {},
      down: {},
      counter: 1,
    },
    wordSearch: [],
    sorting: {},
    ...DEFAULT_QUESTION_SETTINGS,
  },
  kind: 'matching',
  yearGroups: KEY_STAGES[0].years,
  difficulty: 1,
  marking: 1,
  ...DEFAULT_ALL_TYPE,
}

export const DEFAULT_DOODLE_FORM: Omit<
  DoodleItemFormValue,
  'id' | 'directory'
> = {
  questionType: 'doodle',
  allowVerbal: false,
  title: '',
  body: '',
  image: undefined,
  setting: {
    ...DEFAULT_QUESTION_SETTINGS,
  },
  kind: 'blank',
  yearGroups: KEY_STAGES[0].years,
  difficulty: 1,
  marking: 1,
  ...DEFAULT_ALL_TYPE,
}

export const DEFAULT_QUESTION_FORM: Record<
  LessonQuestionType,
  Omit<LessonTrackFormValue, 'id' | 'directory'>
> = {
  essay: DEFAULT_ESSAY_FORM,
  mcq: DEFAULT_MCQ_FORM,
  dnd: DEFAULT_DND_ITEM_FORM,
  puzzle: DEFAULT_PUZZLE_FORM,
  doodle: DEFAULT_DOODLE_FORM,
  game: DEFAULT_PUZZLE_FORM,
}
