import { MouseEventHandler } from 'react'
import { Accept } from 'react-dropzone'

export enum FileManagerTab {
  Browser = 'browser',
  Uploader = 'uploader',
  AI = 'ai',
}
export const FILE_MANAGER_TABS: Readonly<FileManagerTab[]> =
  Object.values(FileManagerTab)

export enum FileManagerTabFlag {
  Browser = 1 << 0,
  Uploader = 1 << 1,
  AI = 1 << 2,
  All = FileManagerTabFlag.Browser |
    FileManagerTabFlag.Uploader |
    FileManagerTabFlag.AI,
}

export interface FileManagerContextValue {
  id?: string
  /**
   * trigger when files selected
   * @param next
   */
  onChange: (next: FsItem[]) => void
  /**
   * trigger when files is uploaded
   * @param files
   */
  onUpload: (files: File[]) => Promise<FsItem[]>
  // how many columns to display
  displayColumn?: number
  activeTab: FileManagerTab
  setActiveTab: SetState<FileManagerTab>
  multiple?: boolean
  // browser panel selected
  selected: FsItem[]
  setSelected: SetState<FsItem[]>
  toggleSelected: (item: FsItem) => void
  isSelected: (item: FsItem) => boolean
  // uploader panel files
  files: File[]
  setFiles: SetState<File[]>
  removeFile: (f: File) => void
  isUploading: boolean
  setIsUploading: SetState<boolean>
  modal?: {
    open: boolean
    handler: () => void
  }
  accept?: Accept
  tabFlags: FileManagerTabFlag
  contentType?: 'image' | 'video' | 'audio' | 'all'
  aiImage: null | {
    url: string
    buffer: File | null
  }
  setAiImage: SetState<null | {
    url: string
    buffer: File | null
  }>
  aiPrompt?: string
  aiImageName?: string
  onPromptChange?: (val: string, e: React.ChangeEvent) => void
}

export type FileManagerProps = Pick<
  FileManagerContextValue,
  | 'onChange'
  | 'onUpload'
  | 'displayColumn'
  | 'id'
  | 'multiple'
  | 'accept'
  | 'aiPrompt'
  | 'aiImageName'
  | 'onPromptChange'
  | 'contentType'
> & {
  tabFlags?: FileManagerTabFlag
  modal?: {
    open: boolean
    handler: () => void
  }
}

export type FileManagerInputProps = {
  tabFlags?: FileManagerTabFlag
  id?: string
  className?: string
  onClick?: MouseEventHandler
  selectedFiles: string[]
  onSelect: (fileIds: string[]) => void
  onUpload: (files: File[]) => Promise<FsItem[]>
  btnIcon?: string
  multiple?: boolean
  label?: string
  accept?: Accept
  aiPrompt?: string
  aiImageName?: string
  onPromptChange?: (val: string, e: React.ChangeEvent) => void
  contentType?: 'image' | 'video' | 'audio' | 'all'
  preview?: boolean
}
